// export const MINIMUM_PRICE = 0.01;

import { isAppPWA } from './helper-functions';

export const MINIMUM_PRICE = 0.5;
export const isAllowedToPay = (cartItems) => {
  return cartItems && (cartItems.promoPrice >= MINIMUM_PRICE
    || (cartItems.promoPrice === 0 && cartItems.itemsPrice >= MINIMUM_PRICE));
};

export const isAllowToChangeStore = (store) => {
  const exclusiveStores = process.env.REACT_APP_EXCLUSIVE_STORES.split(',');
  return !exclusiveStores.some(element => store.name.toUpperCase().includes(element)) || isAppPWA()
}
export const isPayzenModule = (store) => {
  return store?.paymentGateway === 'payzen'
}
export const isTRavailable = (cartItems) => {
  return !cartItems.some(item => item.TR === false)
}

export const isLessThan_Minutes = (user, minutes) => {
  const TEN_MINUTES_IN_MS = minutes * 60 * 1000;
  if (!user || !user.createdAt) return false;
  const createdAt = new Date(user.createdAt);
  const currentTime = new Date();
  const differenceInMs = currentTime - createdAt;

  return differenceInMs < TEN_MINUTES_IN_MS;
};

export const isTRissuerExcluded = (issuerName) => {
  const excludedIssuers = process.env.REACT_APP_PAYZEN_EXCLUDED_TR_ISSUER.split(',');
  return excludedIssuers.some(element => issuerName.toUpperCase().includes(element))
}